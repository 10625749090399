@import '../chat/_colors.scss';

$message-time-color: darken($accent-color, 46.5%);
$speech-bubble-border-color: darken($accent-color, 13.18);

.message-row {
    display: grid;
    grid-template-columns: 85%;
    margin-bottom: 0px;
    word-break: break-word;

    > .message-content {
        display: grid;

        > img {
            border-radius: 100%;
            grid-row: span 2;
            width: 40px;
            height: 40px;
        }
        
        > .message-time {
            font-size: 0.7rem;
            color: $message-time-color;
        }
        
        > .message-text {
            padding: 9px 14px;
            font-size: 14px !important;
            margin-bottom: 0px;
        }
    }

    &.you-message {
        justify-content: end;

        > .message-content {
            justify-items: end;

            > .message-text {
                background: white;
                color: #444444;
                border: 1px solid white;
                border-radius: 14px 14px 0 14px;
            }
        }
    }

    &.other-message {
        justify-items: start;

        > .message-content {
            grid-template-columns: 35px 1fr;
            grid-column-gap: 15px;

            > .message-text {
                background: #E7E9EE; 
                color: #0c193c;
                border: 1px solid #E7E9EE;
                border-radius: 14px 14px 14px 0;
            }
        }
    }
}