@import './_colors.scss';

$chat-title-shadow: darken($accent-color, 75.29);

#chat-title {
    display: grid;
    grid: 36px / 1fr 36px;
    align-content: center;
    align-items: center;
    grid-area: chat-title;
    background: #173278;
    color: white;
    font-weight: bold;
    font-size: 19px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 1px 3px -1px $chat-title-shadow;
    padding: 0 20px;
    z-index: 1;
}