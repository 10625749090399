.chat{
    right: 0;
    bottom: 0;
    z-index: 999999999;
    margin-right: 30px;
    margin-bottom: 30px;
    position: fixed
}

@media (max-width: 700px) {
    .chat{
        margin-bottom: 5px;
    }
  }