@import './_colors.scss';

$chat-shell-background: lighten($accent-color, 65%);

.chat-container {
  display: grid;
  grid:
      'chat-title' 50px
      /*'subir-media' 25px*/
      'chat-message-list' 1fr
      'chat-button' 30%
      'progress-bar' 25px
      'chat-form' 50px
      ;
  //min-width: 800px;
  max-width: 400px;
  //max-height: 800px;
  width: 100%;
  height: 85vh;
  margin:10px;
  background: #EEEEEE;
  border-radius:  10px ;
  z-index: 999999999;
}
.chat-formulario {
  display: grid;
  grid:
      'chat-title' 50px
      'chat-message-list' 1fr
      'chat-form' 20px
      ;
  //min-width: 800px;
  max-width: 400px;
  //max-height: 800px;
  width: 100%;
  height: 100%;
  margin:10px;
  background: #EEEEEE;
  border-radius:  10px ;
  z-index: 999999999;
}


.chat-container-nobutton {
  display: grid;
  grid:
      'chat-title' 50px
      /*'subir-media' 25px*/
      'chat-message-list' 1fr
      'progress-bar' 25px
      'chat-form' 50px
      ;
  //min-width: 800px;
  max-width: 400px;
  //max-height: 800px;
  width: 100%;
  height: 85vh;
  margin:10px;
  background: #EEEEEE;
  border-radius:  10px ;
  z-index: 2247483646 !important;
}

.chat-container-noprogressbar {
  display: grid;
  grid:
      'chat-title' 50px
      /*'subir-media' 25px*/
      'chat-message-list' 1fr
      'chat-button' 30%
      'chat-form' 50px
      ;
  //min-width: 800px;
  max-width: 400px;
  //max-height: 800px;
  width: 100%;
  height: 85vh;
  margin:10px;
  background: #EEEEEE;
  border-radius:  10px ;
  z-index: 999;
}


.chat-container-nobutton-noprogressbar {
  display: grid;
  grid:
      'chat-title' 50px
      /*'subir-media' 25px*/
      'chat-message-list' 1fr
      'chat-form' 50px
      ;
  //min-width: 800px;
  max-width: 400px;
  //max-height: 800px;
  width: 100%;
  height: 85vh;
  margin:10px;
  background: #EEEEEE;
  border-radius:  10px ;
  z-index: 999;
}

.divCaptcha{
  display: flex;
  justify-content: center;
  padding: 15px
}
@media (max-width: 800px) {
  #g-recaptcha {
    transform:scale(0.70) !important;
    -webkit-transform:scale(0.70) !important;
    }
    .chat-container {
      height: 88vh;
    }
    .chat-container-nobutton {
      height: 88vh;
    }
    
    .chat-container-noprogressbar {
      height: 88vh;
    }
    .chat-container-nobutton-noprogressbar {
      height: 88vh;
    }
    .chat-formulario {
      height: 100%;
    }
}

@media (max-height: 650px) {
    .chat-formulario {
      height: 88vh;
    }
}
