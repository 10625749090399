@import './_colors.scss';

$input-border-color: darken($accent-color, 6.5%);
$input-text-color: darken(adjust-hue($primary-color, -155), 23.33);
$chat-form-top-border: darken($accent-color, 12.16);

#chat-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-area: chat-form;
  background: $accent-color;
  border-radius: 0 0 10px 10px;
  border-top: 1px solid $chat-form-top-border;
  padding-left: 42px;
  padding-right: 22px;

  input {
    flex: 1 0 0;
    outline: none;
    padding: 8px;
    border: 2px solid $input-border-color;
    border-right: none;
    color: $input-text-color;
    border-radius: 6px 0 0 6px;
    font-size: 0.8rem;
    margin-left: 15px;
  }

  button {
    height: 35px;
    border-radius: 0 6px 6px 0;
  }
}

#form-chat {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  gap: 1%;
  padding: 0px 5px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  height: 100%;

  input {
    flex: 1 0 0;
    outline: none;
    border: none;
    color: black;
    font-size: 0.8rem;
    margin-left: 15px;
    
  }
  button {
    height: 35px;
    border-radius: 0 6px 6px 0;
  }

}