#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px 20px;
  overflow-y: scroll;
}
#chat-button {
  grid-area: chat-button;
  gap: 5%;
  overflow-y: auto;
  button{
    flex-grow: 1;
    background: none;
    color: black; 
    border-radius: 15px;
    width: 100%;
    border: 2px solid #173278;
    cursor: pointer;
    &:hover{
      background: #173278;
      color: white;
    }
  }
}